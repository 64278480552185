.AuthLayout,
.AuthLayout .CenteredContainer,
.AuthLayout .CenteredContainer > * {
  display: flex;
  flex-direction: column;
}

.AuthLayout {
  background-color: RGB(var(--color-background--level-1));

  .CenteredContainer {
    margin: auto;
    width: 100%;
    max-width: 45em;
    padding: var(--website-padding);
  }

  .WalyLogo {
    padding: 6em var(--website-padding);
    height: 19.2rem;
  }

  h1,
  p {
    text-align: center;
  }

  h1 {
    margin-bottom: 0.5em;
  }

  p {
    color: RGB(var(--color-default--p-level-3));
    margin-bottom: 2em;
  }

  .WalyButton {
    margin-bottom: 1em;
  }

  .WalyInputField {
    margin-bottom: 2em;
  }

  hr {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: 0.5;
    width: 100%;
    margin: 3em 0 4em 0;

    &:before {
      content: '';
      // use the linear-gradient for the fading effect
      // use a solid background color for a solid bar
      background: RGB(var(--color-default--p-level-3));
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }

    &:after {
      content: attr(data-content);
      position: relative;
      display: inline-block;
      padding: 0 0.5em;
      line-height: 1.5em;
      // this is really the only tricky part, you need to specify the background color of the container element...
      color: RGB(var(--color-default--p-level-3));
      background-color: RGB(var(--color-background--level-1));
    }
  }
}
