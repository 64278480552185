@import 'fonts';
@import 'normalize';
@import 'variables';

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

*::selection,
*::-moz-selection {
  background-color: rgba(13, 0, 255, 0.45);
  color: white;
}

html,
body {
  font-family: var(--font-default);
  font-weight: var(--font-default-weight);
  color: RGB(var(--color-default--p));
  font-feature-settings: 'ss01', 'ss02';
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
}

code {
  font-family: 'JetBrains Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root,
.WalyApp {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: RGB(var(--color-default--hn));
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

h1 {
  font-size: 3.2em;
}

p,
li,
span {
  font-size: 1.5em;
  line-height: 1.5em;
}

.DisableTextSelection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
