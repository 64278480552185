.NavigationHolder {
  grid-area: Navigation;
  background: RGB(var(--color-background--level-1));
  border-right: 1px solid RGB(var(--color-border));
  padding: 1.2rem 1rem 1.8rem 1rem;

  .NavSidebar,
  .NavItemGroup {
    display: flex;
    flex-direction: column;
  }

  .NavSidebar {
    height: 100%;
  }

  .NavItemGroup {
    margin-bottom: 2em;

    &.BottomGroup {
      margin-top: auto;
      margin-bottom: 0;

      .NavItem {
        color: RGB(var(--color-default--p-level-3));
      }

      .NavItemIcon,
      .NavItemTitle {
        font-size: 1.2rem;
      }
    }

    .NavTitle,
    .NavItem {
      cursor: pointer;
      padding-left: 0.9rem;
      padding-right: 0.9rem;
      border-radius: 0.4rem;
      font-family: 'Inter';
      transition: color 100ms ease-in-out, background-color 100ms ease-in-out;

      &:hover {
        background-color: RGBA(var(--color-grey), 0.08);
      }
    }

    .NavTitle {
      font-size: 1em;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      width: fit-content;
      font-weight: 500;
      margin-bottom: 0.6rem;
      color: RGB(var(--color-default--p-level-3));
    }

    .NavItem {
      position: relative;
      font-size: 9px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 3.4rem;
      text-decoration: none;
      font-weight: 400;
      margin-bottom: 0.4rem;
      color: RGB(var(--color-default--p-level-2));

      &:hover {
        color: RGB(var(--color-default--hn));
      }

      &.active {
        font-weight: 600;
        color: RGB(var(--color-primary--darker));
        background-color: RGBA(var(--color-primary), 0.14);
      }
    }

    .NavItemIcon {
      position: absolute;
      left: 0.9rem;
      top: 0.57rem;
      width: 1.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .NavItemTitle {
      font-size: 1.3rem;
      line-height: 1.3em;
      letter-spacing: -0.4px;
    }
  }
}
