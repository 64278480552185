html {
  // Fonts
  --font-fallback: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-default: 'Coven', var(--font-fallback);
  --font-default-weight: 400;
  --font-default-line-height: 1.5em;
  // Sizes
  --website-max-width: 95%;
  --website-padding: 2.5em;
  --website-nav-height: 7.5em;
}

.WalyApp.WalyLight {
  // Colors
  --color-primary: 120, 127, 246;
  --color-primary--darker: 93, 100, 215;
  --color-accent: 123, 213, 245;
  --color-accent--darker: 7, 150, 202;
  --color-grey: 99, 102, 145;
  --color-border: 234, 234, 234;
  --color-border--darker: 223, 225, 228;
  --color-default--p: 66, 66, 66;
  --color-default--p-level-2: 63, 63, 63;
  --color-default--p-level-3: 128, 128, 128;
  --color-default--h1: 0, 0, 0;
  --color-background--level-1: 255, 255, 255;
  --color-background--level-2: 250, 250, 250;
  --color-background--level-3: 255, 255, 255;
  --color-pure-jing-jang: 0, 0, 0;
  --color-placeholder: 218, 218, 218;

  --box-shadow--small: rgba(0, 0, 0, 0.07) 0px 1px 1px;
}

.WalyApp.WalyDark {
  --color-primary: 120, 127, 246;
  --color-primary--darker: 149 155 255;
  --color-accent: 123, 213, 245;
  --color-accent--darker: 7, 150, 202;
  --color-grey: 99, 102, 145;
  --color-border: 48, 50, 54;
  --color-border--darker: 60, 63, 68;
  --color-default--p: 212, 212, 212;
  --color-default--p-level-2: 191, 191, 191;
  --color-default--p-level-3: 128, 128, 128;
  --color-default--hn: 232, 232, 232;
  --color-background--level-1: 31, 32, 35;
  --color-background--level-2: 31, 32, 35;
  --color-background--level-3: 48, 50, 54;
  --color-pure-yin-yang: 255, 255, 255;
  --color-placeholder: 62, 62, 62;

  --box-shadow--small: rgba(0, 0, 0, 0.25) 0px 1px 1px;
}
