.WalyInputField {
  display: inline-block;
  position: relative;

  label {
    font-size: 1.3em;
    margin-bottom: 0.7em;
    display: block;
    padding-left: 0.2em;
    color: RGB(var(--color-default--p));
  }

  input,
  textarea {
    display: block;
    font-size: 1.5em;
    padding: 0.8em 1.4em 0.85em 1.4em;
    background-color: transparent;
    border: solid 0.1em RGB(var(--color-border--darker));
    border-radius: 0.4em;
    outline: none;
    width: 100%;
    color: RGB(var(--color-default--p));

    &:hover {
      box-shadow: 0 0 0 0.18em RGBA(var(--color-grey), 0.15);
    }

    &:focus {
      box-shadow: 0 0 0 0.08em RGB(var(--color-primary)), 0 0 0 0.25em RGBA(var(--color-primary), 0.3);
    }

    &::placeholder {
      color: RGB(var(--color-placeholder));
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: RGB(var(--color-placeholder));
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: RGB(var(--color-placeholder));
    }
  }
}
