.HeaderHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: RGB(var(--color-background--level-1));
  border-bottom: 1px solid RGB(var(--color-border));
  padding: 1.2rem 2.4rem;

  .WalyLogo {
    position: relative;
    top: 0.2rem;
    height: 100%;
    width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 100%;
    }
  }

  .PageTitle {
    position: relative;
    margin-bottom: 0;
    margin-left: 2.4rem;
    padding-left: 2.4rem;
    font-size: 1.8rem;
    font-weight: 400;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      height: 100%;
      width: 1px;
      background-color: RGB(var(--color-border));
    }
  }

  .HeaderToolsArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    height: 100%;
  }
}
