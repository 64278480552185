.WalyButton {
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  border: none;
  margin: 0;
  text-decoration: none;
  text-align: center;
  align-items: center;
  justify-content: center;

  &.TypeSmall,
  &.TypeSmallTransparent {
    white-space: nowrap;
    border-radius: 0.4em;
    flex-shrink: 0;
    font-weight: 500;
    line-height: normal;
    transition-property: border, background, color, box-shadow;
    transition-duration: 0.1s;
    -webkit-user-select: none;
    user-select: none;
    -webkit-app-region: no-drag;
    min-width: 2.8em;
    height: 2.6em;
    padding: 0 1.1em 0.1em 1.1em;

    svg {
      height: 100%;
      width: 1.5em;
      padding: 0.48em 0 0.4em 0;
      margin-right: 0.5em;
      font-size: 0.9em;
    }
  }

  &.TypeSmall {
    font-size: 1.1rem;
    border: 1px solid RGB(var(--color-border--darker));
    box-shadow: var(--box-shadow--small);
    background: RGB(var(--color-background--level-3));
    color: RGB(var(--color-default--p));
  }

  &.TypeSmallTransparent {
    background-color: transparent;
    color: RGB(var(--color-primary));
    border: 1px solid transparent;
    font-size: 1.3rem;

    &:hover {
      background-color: RGBA(var(--color-primary), 0.05);
    }
  }

  &.TypeTransparent,
  &.TypeFlat,
  &.TypeRaised {
    font-size: 1.5em;
    padding: 0.8em 2em 0.85em 2em;
    border-radius: 5em;
    transition: background-color 150ms ease-in-out, color 100ms ease-in-out, box-shadow 200ms ease-in-out;
  }

  &:hover {
    font-weight: 500;
  }

  &:focus {
    outline: none;
  }

  &.TypeTransparent {
    color: RGB(var(--color-default--p));
    background-color: transparent;

    &:hover {
      background-color: RGBA(var(--color-primary), 0.05);
    }
  }

  &.TypeFlat {
    color: RGB(var(--color-accent--darker));
    background-color: RGBA(var(--color-accent), 0.14);

    &:hover {
      background-color: RGBA(var(--color-accent), 0.19);
    }
  }

  &.TypeRaised {
    color: white;
    background-color: RGB(var(--color-primary));

    &:hover {
      background-color: RGB(var(--color-primary--darker));
      box-shadow: 2px 4px 15px 2px RGBA(var(--color-primary--darker), 0.2);
    }
  }
}
