@font-face {
  font-family: 'Coven';
  src: url('/fonts/coven/coven-light.eot?#iefix') format('embedded-opentype'), url('/fonts/coven/coven-light.woff') format('woff'),
    url('/fonts/coven/coven-light.ttf') format('truetype'), url('/fonts/coven/coven-light.svg#coven-light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Coven';
  src: url('/fonts/coven/coven.eot?#iefix') format('embedded-opentype'), url('/fonts/coven/coven.otf') format('opentype'),
    url('/fonts/coven/coven.woff') format('woff'), url('/fonts/coven/coven.ttf') format('truetype'),
    url('/fonts/coven/coven.svg#coven') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Coven';
  src: url('/fonts/coven/coven-medium.eot?#iefix') format('embedded-opentype'), url('/fonts/coven/coven-medium.woff') format('woff'),
    url('/fonts/coven/coven-medium.ttf') format('truetype'), url('/fonts/coven/coven-medium.svg#coven-medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-BoldItalic.eot');
  src: local('Inter Bold Italic'), local('Inter-BoldItalic'), url('/fonts/inter-ui/Inter-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-BoldItalic.woff2') format('woff2'), url('/fonts/inter-ui/Inter-BoldItalic.woff') format('woff'),
    url('/fonts/inter-ui/Inter-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-MediumItalic.eot');
  src: local('Inter Medium Italic'), local('Inter-MediumItalic'),
    url('/fonts/inter-ui/Inter-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-MediumItalic.woff2') format('woff2'), url('/fonts/inter-ui/Inter-MediumItalic.woff') format('woff'),
    url('/fonts/inter-ui/Inter-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-ThinItalicBETA.eot');
  src: local('Inter Thin Italic BETA'), local('Inter-ThinItalicBETA'),
    url('/fonts/inter-ui/Inter-ThinItalicBETA.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-ThinItalicBETA.woff2') format('woff2'), url('/fonts/inter-ui/Inter-ThinItalicBETA.woff') format('woff'),
    url('/fonts/inter-ui/Inter-ThinItalicBETA.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-Regular.eot');
  src: local('Inter Regular'), local('Inter-Regular'), url('/fonts/inter-ui/Inter-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-Regular.woff2') format('woff2'), url('/fonts/inter-ui/Inter-Regular.woff') format('woff'),
    url('/fonts/inter-ui/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-Black.eot');
  src: local('Inter Black'), local('Inter-Black'), url('/fonts/inter-ui/Inter-Black.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-Black.woff2') format('woff2'), url('/fonts/inter-ui/Inter-Black.woff') format('woff'),
    url('/fonts/inter-ui/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-BlackItalic.eot');
  src: local('Inter Black Italic'), local('Inter-BlackItalic'),
    url('/fonts/inter-ui/Inter-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-BlackItalic.woff2') format('woff2'), url('/fonts/inter-ui/Inter-BlackItalic.woff') format('woff'),
    url('/fonts/inter-ui/Inter-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-LightBETA.eot');
  src: local('Inter Light BETA'), local('Inter-LightBETA'), url('/fonts/inter-ui/Inter-LightBETA.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-LightBETA.woff2') format('woff2'), url('/fonts/inter-ui/Inter-LightBETA.woff') format('woff'),
    url('/fonts/inter-ui/Inter-LightBETA.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-SemiBold.eot');
  src: local('Inter Semi Bold'), local('Inter-SemiBold'), url('/fonts/inter-ui/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-SemiBold.woff2') format('woff2'), url('/fonts/inter-ui/Inter-SemiBold.woff') format('woff'),
    url('/fonts/inter-ui/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-ExtraBoldItalic.eot');
  src: local('Inter Extra Bold Italic'), local('Inter-ExtraBoldItalic'),
    url('/fonts/inter-ui/Inter-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-ExtraBoldItalic.woff2') format('woff2'), url('/fonts/inter-ui/Inter-ExtraBoldItalic.woff') format('woff'),
    url('/fonts/inter-ui/Inter-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-Bold.eot');
  src: local('Inter Bold'), local('Inter-Bold'), url('/fonts/inter-ui/Inter-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-Bold.woff2') format('woff2'), url('/fonts/inter-ui/Inter-Bold.woff') format('woff'),
    url('/fonts/inter-ui/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-ExtraLightItalicBETA.eot');
  src: local('Inter Extra Light Italic BETA'), local('Inter-ExtraLightItalicBETA'),
    url('/fonts/inter-ui/Inter-ExtraLightItalicBETA.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-ExtraLightItalicBETA.woff2') format('woff2'),
    url('/fonts/inter-ui/Inter-ExtraLightItalicBETA.woff') format('woff'),
    url('/fonts/inter-ui/Inter-ExtraLightItalicBETA.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-ExtraLightBETA.eot');
  src: local('Inter Extra Light BETA'), local('Inter-ExtraLightBETA'),
    url('/fonts/inter-ui/Inter-ExtraLightBETA.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-ExtraLightBETA.woff2') format('woff2'), url('/fonts/inter-ui/Inter-ExtraLightBETA.woff') format('woff'),
    url('/fonts/inter-ui/Inter-ExtraLightBETA.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-ExtraBold.eot');
  src: local('Inter Extra Bold'), local('Inter-ExtraBold'), url('/fonts/inter-ui/Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-ExtraBold.woff2') format('woff2'), url('/fonts/inter-ui/Inter-ExtraBold.woff') format('woff'),
    url('/fonts/inter-ui/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-ThinBETA.eot');
  src: local('Inter Thin BETA'), local('Inter-ThinBETA'), url('/fonts/inter-ui/Inter-ThinBETA.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-ThinBETA.woff2') format('woff2'), url('/fonts/inter-ui/Inter-ThinBETA.woff') format('woff'),
    url('/fonts/inter-ui/Inter-ThinBETA.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-LightItalicBETA.eot');
  src: local('Inter Light Italic BETA'), local('Inter-LightItalicBETA'),
    url('/fonts/inter-ui/Inter-LightItalicBETA.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-LightItalicBETA.woff2') format('woff2'), url('/fonts/inter-ui/Inter-LightItalicBETA.woff') format('woff'),
    url('/fonts/inter-ui/Inter-LightItalicBETA.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-Italic.eot');
  src: local('Inter Italic'), local('Inter-Italic'), url('/fonts/inter-ui/Inter-Italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-Italic.woff2') format('woff2'), url('/fonts/inter-ui/Inter-Italic.woff') format('woff'),
    url('/fonts/inter-ui/Inter-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-SemiBoldItalic.eot');
  src: local('Inter Semi Bold Italic'), local('Inter-SemiBoldItalic'),
    url('/fonts/inter-ui/Inter-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-SemiBoldItalic.woff2') format('woff2'), url('/fonts/inter-ui/Inter-SemiBoldItalic.woff') format('woff'),
    url('/fonts/inter-ui/Inter-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-ui/Inter-Medium.eot');
  src: local('Inter Medium'), local('Inter-Medium'), url('/fonts/inter-ui/Inter-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-ui/Inter-Medium.woff2') format('woff2'), url('/fonts/inter-ui/Inter-Medium.woff') format('woff'),
    url('/fonts/inter-ui/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
