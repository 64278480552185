.DashboardLayout {
  display: grid;
  grid-template-columns: 22.5rem 1fr;
  grid-template-rows: 4.8rem 1fr;
  grid-template-areas:
    'Header Header'
    'Navigation Content';
  background: RGB(var(--color-background--level-2));

  .HeaderHolder {
    grid-area: Header;
  }

  .NavigationHolder {
    grid-area: Navigation;
  }

  .ContentHolder {
    grid-area: Content;
    padding: 3.6rem 3.6rem 0;
  }
}
